<template>
    <card-list>
        <subheader-list-item icon="fa-align-left" title="Event information"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Event Type" label-position="inside">
                        <b-select expanded v-model="event.type">
                            <option value="event">Event</option>
                            <option value="meal">Meal</option>
                            <option value="playing">Playing Tennis</option>
                            <option value="watching">Watching Tennis</option>
                            <option value="hotel">Hotel</option>
                            <option value="tour">Tour</option>
                            <option value="bus">Bus/Vehicle Transfer</option>
                            <option value="transfer">Other Transfer</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column is-8">
                    <b-field label="Title" label-position="inside">
                        <b-input v-model="event.title"></b-input>
                    </b-field>
                </div>

                <div class="column">
                    <b-field label="Location" label-position="inside">
                        <b-input v-model="event.location"/>
                    </b-field>
                </div>
            </div>
            <div class="columns" v-show="event.type === 'bus' || event.type === 'transfer' || event.type === 'tour'">
                <div class="column">
                    <b-field label="Start/Pickup Location" label-position="inside">
                        <b-input v-model="event.start_location"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column is-4">
                    <b-field label="Start Date" label-position="inside">
                        <b-input type="date" v-model="event.start_date"/>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="Start Time" label-position="inside">
                        <b-input type="time" v-model="event.start_time"/>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Timezone" label-position="inside">
                        <b-autocomplete
                                :data="startTzResults"
                                field="title"
                                v-model="event.start_tz"
                                @select="selectTZ"
                                icon="search"
                                open-on-focus
                                placeholder="Search...">

                            <template slot="empty">No results.</template>
                        </b-autocomplete>
                    </b-field>
                </div>
            </div>

            <div class="columns" v-show="event.type === 'bus' || event.type === 'transfer' || event.type === 'tour'">
                <div class="column">
                    <b-field label="End/Drop-off Location" label-position="inside">
                        <b-input v-model="event.end_location"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column is-4">
                    <b-field label="End Date" label-position="inside">
                        <b-input type="date" v-model="event.end_date"/>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="End Time" label-position="inside">
                        <b-input type="time" v-model="event.end_time"/>
                    </b-field>
                </div>

                <div class="column">
                    <b-field label="Timezone" label-position="inside">
                        <b-autocomplete
                                :data="endTzResults"
                                field="title"
                                v-model="event.end_tz"
                                @select="selectTZ"
                                icon="search"
                                open-on-focus
                                placeholder="Search...">

                            <template slot="empty">No results.</template>
                        </b-autocomplete>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-switch :true-value="1" :false-value="0" v-model="event.is_hidden">Hidden on Website</b-switch>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Description" label-position="inside">
                        <b-input type="textarea" v-model="event.description"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Critical Participant Information" label-position="inside">
                        <b-input type="textarea" v-model="event.critical_info"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Vendor Information" label-position="inside">
                        <b-input type="textarea" v-model="event.vendor_info"/>
                    </b-field>
                </div>
            </div>
        </container-list-item>
        <subheader-list-item icon="fa-tasks" title="Task"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Status" label-position="inside">
                        <b-select expanded v-model="event.task_status">
                            <option value="planned">Planned</option>
                            <option value="booked">Booked</option>
                            <option value="confirmed">Confirmed</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Payment" label-position="inside">
                        <b-select expanded v-model="event.task_payment">
                            <option value="unpaid">Unpaid</option>
                            <option value="deposit">Deposit</option>
                            <option value="paid">Paid</option>
                            <option value="na">N/A</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Assignment" label-position="inside">
                        <b-select expanded v-model="event.task_assigned_id">
                            <option :value="a.id" v-for="a in assignees">{{a.first_name}} {{a.last_name}}</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-switch :native-value="1" :true-value="1" :false-value="0" v-model="event.task_complete">Complete</b-switch>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Notes" label-position="inside">
                        <b-input type="textarea" v-model="event.task_notes"/>
                    </b-field>
                </div>
            </div>
        </container-list-item>
    </card-list>
</template>

<script>
    import CardList from '../../TIER/components/CardList';
    import SubheaderListItem from '../../TIER/components/SubheaderListItem';
    import ContainerListItem from '../../TIER/components/ContainerListItem';

    import moment from 'moment-timezone';
    import {mapGetters} from "vuex";

    export default {
        name: 'EventForm',
        props: ['event'],
        components: {ContainerListItem, SubheaderListItem, CardList},
        data: function () {
            return {
                zones: moment.tz.names(),
            };
        },
        computed: {
            ...mapGetters(['assignees']),
            startTzResults() {
                if (this.event && this.event.start_tz) {
                    return this.zones.filter((option) => {
                        return option
                                .toString()
                                .toLowerCase()
                                .indexOf(this.event.start_tz.toLowerCase()) >= 0;
                    });
                } else {
                    return null;
                }
            },
            endTzResults() {
                if (this.event && this.event.end_tz) {
                    return this.zones.filter((option) => {
                        return option
                                .toString()
                                .toLowerCase()
                                .indexOf(this.event.end_tz.toLowerCase()) >= 0;
                    });
                } else {
                    return null;
                }
            }
        },
        methods: {
            selectTZ(option) {
                // console.log('Selected', moment().tz(option).format('MMMM Do YYYY, h:mm:ss a Z'));
            }
        }
    };
</script>
